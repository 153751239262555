.main {
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
    margin-top: 4rem;
  }
  /* .greet-main {
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
    margin-top: 2rem;
  } */
  
  .gretting-main {
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
  }
  
  .greeting-main {
    display: flex;
  }
  
  .greeting-main > * {
    flex: 1;
    margin-bottom: 30px;
  }
  .button-greeting-div {
    display: flex;
    margin-top: 20px;
  }
  
  .greeting-text {
    margin-top: 80px;
    font-size: 70px;
    line-height: 1.1;
    font-family: 'Bebas Neue', sans-serif;
    color:#276461;
  }
  
  .greeting-nickname {
    font-size: 30px;
    line-height: 0px;
    font-family: "Bebas Neue", sans-serif;
    color: #276461
  }
  
  .greeting-text-p {
    font-size: 40px;
    line-height: 40px;
    margin-right: 40px;
    font-family: "Inter", sans-serif;
    color: #5f9491;
  }
  
  .greeting-image-div > * {
    max-width: 100%;
    height: auto;
  }
  
  /* Media Query */
  @media (max-width: 1380px) {
    .greeting-text {
      font-size: 50px;
    }
    .greeting-text-p {
      font-size: 20px;
    }
  }
  @media (max-width: 768px) {
    .button-greeting-div {
      justify-content: space-around;
    }
    .greeting-text {
      font-size: 30px;
      margin-top: 0px;
      text-align: center;
    }
    .greeting-nickname {
      font-size: 25px;
      text-align: center;
      font-family: 'Bebas Neue', sans-serif;
    }
    .greeting-text-p {
      font-size: 14px;
      text-align: center;
      margin-left: 40px;
      line-height: 1.5; /* Set line height to a numeric value to improve readability */
    }
    
    .greeting-main {
      display: block;
    }
  
    .portfolio-repo-btn-div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }