.project-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.project-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.card-image-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.card-overlay {
  position: absolute;
  inset: 0;
  background: rgba(45, 48, 71, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.project-card:hover .card-overlay {
  opacity: 1;
}

.view-project {
  color: white;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border: 2px solid white;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s;
}

.view-project:hover {
  background: white;
  color: #2D3047;
}

.card-content {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2D3047;
  margin-bottom: 0.75rem;
}

.card-description {
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background: #F3F4F6;
  color: #2D3047;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  transition: all 0.2s;
}

.tag:hover {
  background: #419D78;
  color: white;
}

.card {
    height: 64px;
    position: relative;
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    line-height: 1.5;
    background-color: #fff;
    border-left-width: 8px;
    border-color: #68d391;
    margin-bottom: 40px;
    margin-left: 4px;
    margin-top: 4px;
 }
 
  .card-image {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
    position: absolute;
    display: block;
  }

  .card-title {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 4px;
    padding-bottom: 4px;
  }
  
  .card-link {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 4px;
    padding-bottom: 4px;
  }
  
  .card-title-text {
    color: gray-800;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 3px;
    padding-top: 4px;
    background-color: red-700;
    color: red-100;
    opacity: 0.75;
    border-radius: 10px;
  }

  .hashtag {
    background-color: gray-300;
    color: gray-800;
    padding: 2px 4px;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 10px;
    font-weight:medium;
  }

  .hashtag:hover {
    background-color: blue;
    color: white;
  }
  