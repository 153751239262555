/* NavHeader.css */
.header {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo {
  font-size: 1.75rem;
  font-weight: 700;
  background: linear-gradient(135deg, #419D78 0%, #2D3047 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.2s;
}

.logo-link:hover {
  transform: translateY(-1px);
  opacity: 0.8;
  background: none;
  -webkit-text-fill-color: #419D78;
}

.logo-icon {
  width: 32px;
  height: 32px;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-item {
  color: #6B7280;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.nav-item:hover {
  color: #419D78;
  background-color: rgba(65, 157, 120, 0.1);
}

.nav-item.active {
  color: #419D78;
  background-color: rgba(65, 157, 120, 0.1);
}

.nav-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #419D78;
  border-radius: 2px;
}

/* Remove any other hover styles that might be conflicting */
.menu li a:hover,
.header .menu-btn:hover {
  background-color: rgba(65, 157, 120, 0.1);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
  }

  .hamburger {
    display: block;
    width: 24px;
    height: 2px;
    background: #2D3047;
    position: relative;
    transition: all 0.3s;
  }

  .hamburger::before,
  .hamburger::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background: #2D3047;
    transition: all 0.3s;
  }

  .hamburger::before {
    top: -6px;
  }

  .hamburger::after {
    bottom: -6px;
  }

  .hamburger.open {
    background: transparent;
  }

  .hamburger.open::before {
    transform: rotate(45deg);
    top: 0;
  }

  .hamburger.open::after {
    transform: rotate(-45deg);
    bottom: 0;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .nav-links.show {
    display: flex;
  }
}
